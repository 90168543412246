import AsyncExport from './components/asyncExport'
import AsyncImport from './components/asyncImport'
import CheckboxGroup from './components/checkboxGroup'
import DateRangePicker from './components/dateRangePicker'
import DateTimeRangePicker from './components/dateTimeRangePicker'
import FilesDownload from './components/filesDownload'
import FixedBottomActions from './components/fixedBottomActions'
import { Image, ImagePreview } from './components/image'
import Import from './components/import'
import InfiniteSelect from './components/infiniteSelect'
import InputRange from './components/inputRange'
import ListSort from './components/listSort'
import MonthRange from './components/monthRange'
import Provider from './components/provider'
import QuarterRange, { QuarterPicker } from './components/quarterRange'
import SearchForm from './components/searchForm'
import Select from './components/select'
import Table from './components/table'
import TableLayout from './components/tableLayout'
import Upload from './components/upload'
import YearRange from './components/yearRange'

const components = {
  Provider,
  Table,
  ListSort,
  TableLayout,
  SearchForm,
  InfiniteSelect,
  Select,
  InputRange,
  DateRangePicker,
  QuarterPicker,
  QuarterRange,
  FixedBottomActions,
  MonthRange,
  Image,
  ImagePreview,
  YearRange,
  AsyncImport,
  Upload,
  CheckboxGroup,
  Import,
  FilesDownload,
  DateTimeRangePicker,
  AsyncExport,
}

export default {
  ...components,
  install(Vue) {
    Object.values(components).forEach((component) => {
      Vue.component(component.name, component)
    })
  },
}

import { translate } from '@ys/utils/lib/dict'

/** 导出任务状态 */
export const EXPORT_TASK_STATUS = {
  PENDING: '1',
  COMPLETED: '0',
  PROCESSING: '2',
  FAILED: '4',
}

/** 导出任务状态字典 */
export const EXPORT_TASK_STATUS_DICT = [
  {
    dictKey: EXPORT_TASK_STATUS.PENDING,
    dictValue: '待执行',
  },
  {
    dictKey: EXPORT_TASK_STATUS.COMPLETED,
    dictValue: '已完成',
  },
  {
    dictKey: EXPORT_TASK_STATUS.PROCESSING,
    dictValue: '正在处理',
  },
  {
    dictKey: EXPORT_TASK_STATUS.FAILED,
    dictValue: '导出失败',
  },
]

export const EXPORT_DEFAULT_COLUMNS = [
  {
    title: '文件名称',
    dataIndex: 'bizName',
  },
  {
    title: '创建人',
    dataIndex: 'createUserName',
  },
  {
    title: '导出时间',
    dataIndex: 'createTime',
  },
  {
    title: '处理状态',
    dataIndex: 'status',
    customRender: (text) => translate(text, EXPORT_TASK_STATUS_DICT),
  },
  {
    title: '导出进度',
    dataIndex: 'processValue',
  },
  {
    title: '操作',
    fixed: 'right',
    width: 150,
    scopedSlots: { customRender: 'operation' },
  },
]

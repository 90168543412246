export function usePolling(request, timeout = 10 * 60 * 1000, maxRetries = 3) {
  let timer = null
  let isPolling = false
  let consecutiveFailures = 0

  async function executeRequest() {
    const response = await request()
    return response
  }

  async function poll() {
    try {
      await executeRequest()
      consecutiveFailures = 0
      timer = setTimeout(() => poll(), timeout)
    } catch {
      consecutiveFailures++
      if (consecutiveFailures >= maxRetries) {
        stop()
        return
      }
      setTimeout(() => poll(), 3 * 1000)
    }
  }

  function start() {
    if (isPolling) return
    isPolling = true
    poll()
  }

  function stop() {
    if (!isPolling) return
    clearTimeout(timer)
    isPolling = false
  }

  function restart() {
    stop()
    start()
  }

  return {
    poll,
    start,
    stop,
    restart,
  }
}
